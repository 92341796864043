const API_URL: string | undefined = import.meta.env.VITE_API_URL as string | undefined;

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { addHours } from 'date-fns';
import i18n from 'i18next';
import jwtDecode from 'jwt-decode';

import {
  ERROR_CODES,
  ERROR_CUSTOM_SNACKS,
  HTTP_STATUS_MESSAGES,
  getAxiosResponseErrorCodeExceptionsList,
  getAxiosResponseErrorMessageExceptionList,
  getAxiosResponseErrorUrlExceptionList,
} from '@/constants';
import { accessToken, getAllStorageData } from '@/storage';
import { Headers, StatusType } from '@enums';
import { addSnack, store, updateApiConnectionStatus, updateToken } from '@store';
import { clearData, validateValueEqual } from '@utils';

export const API: AxiosInstance = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

setInitialHeaders();

export const setHeaders = (headers: { [key: string]: string }, axiosInstance: AxiosInstance = API) => {
  const commonHeaders = { ...axiosInstance.defaults.headers.common };

  Object.keys(headers).forEach((key) => (commonHeaders[key] = headers[key]));

  axiosInstance.defaults.headers.common = commonHeaders;
};

export const deleteHeaders = (headerKeys: string[], axiosInstance: AxiosInstance = API) => {
  const commonHeaders = { ...axiosInstance.defaults.headers.common };

  headerKeys.forEach((key) => commonHeaders[key] && delete commonHeaders[key]);

  axiosInstance.defaults.headers.common = commonHeaders;
};

export function setInitialHeaders() {
  const access_token = accessToken.getItem();
  const headers: Record<string, string> = {};
  if (access_token) {
    API.defaults.headers.common[Headers.Authorization] = `Bearer ${access_token}`;
  }
  return headers;
}

let isRefreshing = false;
let refreshSubscribers: Array<(token: string) => void> = [];

function onRefreshed(token: string) {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
}

function addSubscriber(cb: (token: string) => void) {
  refreshSubscribers.push(cb);
}

API.interceptors.request.use(
  async (config: InternalAxiosRequestConfig<AxiosRequestConfig>) => {
    const authHeader = config.headers[Headers.Authorization] as string;

    if (authHeader?.startsWith('Bearer ')) {
      const token = authHeader.slice(7);
      const { exp, type } = jwtDecode(token) as any;

      if (config.url !== '/user/refresh' && type === 'refresh' && Date.now() >= exp * 1000) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            store.dispatch(updateToken({ toast: false }));
            await new Promise<void>((resolve) => {
              const unsubscribe = store.subscribe(() => {
                const { current_access_token } = getAllStorageData();
                if (current_access_token && current_access_token !== token) {
                  unsubscribe();
                  onRefreshed(current_access_token);
                  resolve();
                }
              });
            });
          } finally {
            isRefreshing = false;
          }
        }

        return new Promise((resolve) => {
          addSubscriber((token) => {
            config.headers[Headers.Authorization] = `Bearer ${token}`;
            resolve(config);
          });
        });
      }
    }

    if (config.data && !(config.data instanceof URLSearchParams)) {
      const multilineFields = (config.headers?.['multiline-fields'] as string)?.split(',') ?? [];
      config.data = clearData(config.data, {
        leaveEmptyArrays: true,
        leaveEmptyStrings: false,
        multilineFields,
      });
      delete config.headers?.['multiline-fields'];
    }

    return config;
  },
  (error: Error) => Promise.reject(error),
);

API.interceptors.response.use(
  (config: AxiosResponse) => config,
  async (
    error: AxiosError<{
      status_code: ERROR_CODES;
      data: object;
      message: string;
      status: string;
      code?: ERROR_CODES[];
    }>,
  ) => {
    let skipMessageDisplay = false;

    if (!error.response) {
      store.dispatch({
        type: addSnack.type,
        payload: {
          type: StatusType.Error,
          message: i18n.t(`errors:SYSTEM_ERROR`),
        },
      });
      return;
    }

    const { status, data } = error.response;
    const errorCode = data.status_code ?? status ?? data?.code;
    const errorMessage = data.message ?? HTTP_STATUS_MESSAGES[status] ?? 'errors:SYSTEM_ERROR';

    const url = error?.config?.url ?? '';
    const codeExceptions = await getAxiosResponseErrorCodeExceptionsList();
    const urlExceptions = await getAxiosResponseErrorUrlExceptionList();
    const messageExceptions = await getAxiosResponseErrorMessageExceptionList();

    if (validateValueEqual(status, 503)) {
      const twoHoursFromNow = addHours(new Date(), 24);
      localStorage.setItem('maintenanceEndTime', twoHoursFromNow.toISOString());
      store.dispatch(updateApiConnectionStatus({ status: false }));
      skipMessageDisplay = true;
    }

    if (!skipMessageDisplay && url) {
      skipMessageDisplay = urlExceptions.some((urlEx) => url.includes(urlEx));
    }

    if (!skipMessageDisplay && errorCode) {
      skipMessageDisplay = codeExceptions.some((exception) => exception === errorCode.toString());
    }

    if (!skipMessageDisplay && errorMessage) {
      skipMessageDisplay = messageExceptions.some((exception) => exception === errorMessage.toString());
    }

    if (!skipMessageDisplay && errorCode) {
      const toastMessage = i18n.exists(`errors:${errorCode}`)
        ? i18n.t(`errors:${errorCode}`, { message: errorMessage })
        : errorMessage;
      store.dispatch({
        type: addSnack.type,
        payload: ERROR_CUSTOM_SNACKS[errorCode.toString() as ERROR_CODES] || {
          type: StatusType.Error,
          message: toastMessage,
        },
      });
    }
    return Promise.reject(error);
  },
);
