import i18n from 'i18next';
import * as Yup from 'yup';

export const securityFormSchema = () => {
  return Yup.object().shape({
    block_request_limit: Yup.number()
      .required(i18n.t('validation:security.blockRequestRequired'))
      .test(
        'block-greater-than-soft',
        () => i18n.t('validation:security.blockRequestMustMoreThanLimitRequest'),
        function (value) {
          const softValue = this.parent.soft_request_limit;
          return !softValue || value > softValue;
        },
      ),
    soft_request_limit: Yup.number(),
  });
};
