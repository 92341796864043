import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { DisclosureBody, DisclosureModal } from '@/components/Disclosure/Disclosure';
import { domainFormSchema } from '@/constants';
import { Dictionaries, getOptionListFromCatalog } from '@/utils';
import { FormField, FormModal } from '@components';
import { CdnConfigurationTab, InputType, columnDirection, originType } from '@enums';
import { Disclosure } from '@headlessui/react';
import { useAppDispatch } from '@hooks';
import {
  getConfigurationList,
  resetConfigurationForm,
  submitConfigurationForm,
  useConfigurationError,
  useConfigurationSaveSuccess,
  useConfigurationSubmitting,
} from '@store';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DomainDataProps } from '../Domain';

interface DomainFormProps {
  data?: DomainDataProps;
  open: boolean;
  isEdit: boolean;
  onClose: () => void;
}

export const DomainModal = ({ data, open, isEdit, onClose }: DomainFormProps) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const tableKey = CdnConfigurationTab.Domain;

  const { t: tButton } = useTranslation('configuration', { keyPrefix: 'buttons.domain' });
  const { t: tForm } = useTranslation('configuration', { keyPrefix: 'forms.domain' });
  const { t: tRouteForm } = useTranslation('configuration', { keyPrefix: 'forms.route' });
  const { t: tOriginForm } = useTranslation('configuration', { keyPrefix: 'forms.origin' });
  const { t: tModal } = useTranslation('configuration', { keyPrefix: 'modals.domain' });
  const { t: tValidation } = useTranslation('validation', { keyPrefix: 'format' });
  const { t: tTables } = useTranslation('common', { keyPrefix: 'tables' });

  const submitError = useConfigurationError(tableKey);
  const isSubmitting = useConfigurationSubmitting(tableKey);
  const saveSuccess = useConfigurationSaveSuccess(tableKey);

  const handleSubmit = (values: DomainDataProps) => {
    const updatedValues = {
      ...values,
      verify_ssl_cert: values.type === originType.HTTPS ? values.verify_ssl_cert : null,
    };
    isEdit
      ? dispatch(
          submitConfigurationForm({
            formData: { ...updatedValues },
            tableKey,
            content_id: data?.id,
            returnResult: true,
          }),
        )
      : dispatch(
          submitConfigurationForm({
            formData: { application_id, ...updatedValues },
            tableKey,
            returnResult: true,
          }),
        );
  };

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      onClose();
      dispatch(resetConfigurationForm(tableKey));
      application_id && dispatch(getConfigurationList({ tableKey, application_id, hasPagination: true }));
    }
  }, [isSubmitting, saveSuccess, submitError]);

  const initialValues: DomainDataProps = {
    domain: data?.domain ?? '',
    name: data?.name ?? '',
    origin: data?.origin ?? '',
    type: data?.type ?? originType.HTTPS,
    connect_timeout: data?.connect_timeout ?? 5,
    send_timeout: data?.send_timeout ?? 5,
    read_timeout: data?.read_timeout ?? 5,
    verify_ssl_cert: data?.verify_ssl_cert ?? true,
  };

  if (!open) return;
  return (
    <Formik initialValues={initialValues} validationSchema={domainFormSchema} onSubmit={handleSubmit}>
      {({ values, touched, errors }) => {
        return (
          <FormModal
            title={tModal(isEdit ? 'editDomain' : 'addNewDomain')}
            onClose={onClose}
            description={tModal(isEdit ? 'editDomainDescription' : 'addNewDomainDescription')}
            submitError={submitError}
            size="lg"
            open={open}
            confirmButton={{ children: tButton(isEdit ? 'editDomain' : 'addDomain') }}
          >
            <FormField
              label={tForm('generalName')}
              isCorrect={touched.name && !errors.name}
              placeholder={tForm('enterYourName')}
              disabled={isSubmitting}
              name="name"
              direction={columnDirection.Row}
              smallFont
              required
            />
            <FormField
              label={tForm('domainName')}
              isCorrect={touched.domain && !errors.domain}
              placeholder={tForm('enterYourDomain')}
              direction={columnDirection.Row}
              disabled={isSubmitting}
              name="domain"
              hints={tValidation('domain')}
              smallFont
              required
            />
            <div className="mt-3">
              <Disclosure>
                <DisclosureModal button={{ label: tForm('advanceOptions'), defaultOpen: data?.origin }}>
                  <DisclosureBody notForm>
                    <FormField
                      label={tOriginForm('originType')}
                      name="type"
                      inputType={InputType.Select}
                      direction={columnDirection.Row}
                      smallFont
                      placeholder={tOriginForm('selectYourOriginType')}
                      fullWidth
                      options={getOptionListFromCatalog(Dictionaries.OriginType)}
                    />
                    <FormField
                      label={tForm('origin')}
                      placeholder={tOriginForm('enterYourIpv4Origin')}
                      direction={columnDirection.Row}
                      disabled={isSubmitting}
                      hints={tValidation('ip')}
                      name="origin"
                      smallFont
                    />
                    <FormField
                      label={tRouteForm('connectionTimeOut')}
                      name="connect_timeout"
                      direction={columnDirection.Row}
                      smallFont
                      min={1}
                      max={60}
                      fullWidth
                      placeholder={'0'}
                      adornment={
                        <article className="text-us flex items-center justify-end text-center w-full text-slate-500">
                          {tTables('seconds')}
                        </article>
                      }
                      type="number"
                    />
                    <FormField
                      label={tRouteForm('sendTimeOut')}
                      name="send_timeout"
                      smallFont
                      min={1}
                      max={60}
                      fullWidth
                      placeholder={'0'}
                      direction={columnDirection.Row}
                      adornment={
                        <article className="text-us flex items-center justify-end text-center w-full text-slate-500">
                          {tTables('seconds')}
                        </article>
                      }
                      type="number"
                    />
                    <FormField
                      label={tRouteForm('readTimeOut')}
                      direction={columnDirection.Row}
                      smallFont
                      min={1}
                      max={1800}
                      fullWidth
                      name="read_timeout"
                      placeholder={'0'}
                      adornment={
                        <article className="text-us flex items-center justify-end text-center w-full text-slate-500">
                          {tTables('seconds')}
                        </article>
                      }
                      type="number"
                    />
                    {values.type === originType.HTTPS && (
                      <FormField
                        label={tOriginForm('verifySSL')}
                        name="verify_ssl_cert"
                        inputType={InputType.Switch}
                        smallFont
                        direction={columnDirection.Row}
                      />
                    )}
                  </DisclosureBody>
                </DisclosureModal>
              </Disclosure>
            </div>
          </FormModal>
        );
      }}
    </Formik>
  );
};
